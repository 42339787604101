/* istanbul ignore file */
import {analytics} from '../../core/firebase/firebaseAuth';
import * as rudderClient from 'rudder-sdk-js';

export const rudderStack = rudderClient;

export const logEvent = async (name, data) => {
  console.log('hereeeeeeeeee');
  await analytics.logEvent(name, data);
  //AppEventsLogger.logEvent(name, data);
};

export const logScreen = async screen => {
  await analytics.logScreenView({
    screen_name: screen,
    screen_class: screen,
  });
};

export const logRudderEvent = async ({
  name,
  step,
  screen,
  extraFrom,
  extraProperties,
  pushState,
}) => {
  const properties = {
    step,
    from: {
      screen,
      ...extraFrom,
    },
    pushState,
    ...extraProperties,
  };
  console.log('Rudder event Step', name, JSON.stringify(properties, null, 2));
  await rudderClient.track(name, properties);
};

export const logNavigateIntent = async (
  module,
  params,
  pushState,
  hasAccess = true,
) => {
  const properties = {
    module,
    hasAccess,
    pushState,
    ...params,
  };
  console.log('navigate_intent', JSON.stringify(properties, null, 2));
  await rudderClient.track('navigate_intent', properties);
};

export const logMainScreen = async (screen, pushState, extraParams) => {
  const properties = {
    screen_name: screen,
    pushState,
    ...extraParams,
  };
  console.log('main_screen_visited', JSON.stringify(properties, null, 2));
  await rudderClient.track('main_screen_visited', properties);
};

export const logSimpleEvent = async (name, extraParams) => {
  const properties = {
    ...extraParams,
  };
  console.log('simple_event', name, JSON.stringify(properties, null, 2));
  await rudderClient.track(name, properties);
};

export const resetRudder = async () => {
  try {
    await rudderClient.reset();
  } catch (e) {
    console.log('Error resetting rudder', e);
  }
};

export const identifyRudder = async (identifier, data) => {
  rudderClient?.identify(identifier, data, null);
};

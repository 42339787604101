/**
 * Conexión a graphQL
 */
/* istanbul ignore file */
import {ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';
import {getToken} from './auth';
import {reportError} from '../helpers/crashlytics/fbCrashlytics';
import {RetryLink} from '@apollo/client/link/retry';

const getClient = async (url, onErrorCallback) => {
  const authLink = setContext(async (_, {headers}) => {
    const token = await getToken();
    if (!token) {
      return {
        headers: {
          ...headers,
        },
      };
    }

    return {
      headers: {
        ...headers,
        authorization: token ? `JWT ${token}` : '',
      },
    };
  });

  const errorLink = onError(({networkError, graphQLErrors}) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({message, locations, path}) => {
        const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
        const error = new Error(errorMsg);
        reportError(
          'graphQL',
          null,
          'onError graphQL',
          error,
          'Error en graphQL',
        );
      });
    }
    if (networkError) {
      const error = new Error(networkError.message);
      reportError(
        'graphQL',
        null,
        'networkError graphQL',
        error,
        'networkError en graphQL',
      );
    }

    if ((graphQLErrors || networkError) && !!onErrorCallback) {
      console.log('network', networkError);
      onErrorCallback({networkError, graphQLErrors});
    }
  });

  // Estado global para conexión
  let isConnected = true;

  // Monitorear estado de red
  const monitorNetwork = () => {
    const NetInfo = require('@react-native-community/netinfo');
    NetInfo.addEventListener(state => {
      isConnected = state.isConnected;
    });
  };

  // Llama al monitor al inicio
  monitorNetwork();

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: 3000,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => {
        console.log(
          '⛔⛔ Error de conexión: is connected: ',
          isConnected,
          error,
        );
        // Reintentar solo si hay error y estamos conectados
        return !!error && isConnected;
      },
    },
  });

  const httpLink = createHttpLink({
    uri: url,
    fetchOptions: {
      timeout: 30000, // 30 seconds timeout
    },
  });

  return new ApolloClient({
    link: authLink.concat(retryLink).concat(errorLink).concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        CompanyType: {
          keyFields: ['publicId'],
        },
        ProfileType: {
          fields: {
            workCompany: {
              merge: true,
            },
          },
        },
      },
    }),
  });
};

export default getClient;

export const firebaseConfig = {
  apiKey: 'AIzaSyC0ic4x4btnOR14e1QRKcA8omcknPwAD-g',
  authDomain: 'speedy-atom-173418.firebaseapp.com',
  databaseURL: 'https://speedy-atom-173418.firebaseio.com',
  projectId: 'speedy-atom-173418',
  storageBucket: 'speedy-atom-173418.appspot.com',
  messagingSenderId: '630768639219',
  appId: '1:630768639219:web:bdb127f87c8f0fc2d96b86',
  measurementId: 'G-QVV6Q1D2PK',
};

export const buildNumberWeb = '200';

export const ENV = 'web-production';

import AsyncStorage from '@react-native-async-storage/async-storage';
import {createContext} from 'react';
import uuid from 'react-native-uuid';
import {
  BOX_ASSISTANT_PHASE,
  BOX_ASSISTANT_STATUS,
} from '../constants/codeConstants';
import {FLAGS_DEFAULT} from '../core/featureFlagService';

export const initialize = {
  onboarding: true, // Si el usuario es nuevo
  authenticated: false, // Si el usuario está autenticado
};

export const userInitialize = {
  userFB: null,
  userCMS: null,
  suscriptionStatus: null,
  country: null,
  timezone: null,
  rudderIdentity: null,
};

export const pushStateInitialize = {
  modoPush: false,
  uuid: uuid.v4(),
  data: {},
};

export const chatPersistentInitialize = {
  conversationFlow: null,
  active: false,
  alive: false,
  firstUserMessage: '',
};

export const boxAssistantInitialize = {
  status: BOX_ASSISTANT_STATUS.CLOSED,
  phase: BOX_ASSISTANT_PHASE.DEAD,
  component: false,
  data: null,
};

export const deviceInfoInitialize = {
  deviceInfo: null,
};

function getInit() {
  AsyncStorage.getItem('session').then(value => {
    if (value) {
      return value;
    }
    return initialize;
  });
}

function initUser() {
  AsyncStorage.getItem('user').then(value => {
    if (value) {
      return value;
    }
    return userInitialize;
  });
}

export const SessionContext = createContext(getInit());
export const UserContext = createContext(initUser());
export const NotificationContext = createContext(null);
export const RemoteConfigContext = createContext(null);
export const DeviceInfoContext = createContext(deviceInfoInitialize);
export const FeatureFlagsContext = createContext(FLAGS_DEFAULT);
export const ExtraInfoContext = createContext({
  floatAudio: false,
  isPlaying: false,
  showAssistantModal: false,
});
export const PushStateContext = createContext(pushStateInitialize);
export const ChatPersistentContext = createContext(chatPersistentInitialize);
export const BoxAssistantContext = createContext(boxAssistantInitialize);

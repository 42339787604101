import AsyncStorage from '@react-native-async-storage/async-storage';
import {Box, Center} from 'native-base';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FlatList} from 'react-native';
import {singOut} from '../../../core/firebase/firebaseAuth';
import {
  initialize,
  SessionContext,
  UserContext,
  userInitialize,
} from '../../../hooks/SessionContext';
import {navigateTo, ROUTE_NAMES} from '../../../navigation/MainNavigator';
import images from '../../../res/images';
import {createChatStyles} from '../../../styles/base';
import ChatMenu from '../../ChatMenu';
import SlidingPanel from '../../SlidingPanel/SlidingPanel';
import MessageChat from '../MessageChat';
import ResponseBox from '../ResponseBox';
import {clearItems} from '../../../hooks/useAsyncStorageData';
import {CHAT_MODULE, CHAT_TYPES} from '../../../constants/codeConstants';
import {getWidthAndHeight} from '../../../helpers/utils/utils';
import {useIsFocused} from '@react-navigation/native';
import {resetRudder} from '../../../helpers/analytics/fbAnalytics';
import WaitingForResponse from '../WaitingForResponse/WaitingForResponse';
import FinishButton from '../FinishButton/FinishButton';

/**
 * Componente  ChatSkeleton.native normal
 */

const ChatSkeleton = ({
  lastMessage,
  messages,
  heightModal,
  finishTextButton,
  onFinishConversation,
  onSendResponse,
  chatStateResponse,
  onStartRecommendation,
  onFinishRecommendation,
  recommendationHappened,
  showFeedback,
  chatModule,
  onStartAnswer = () => {},
  lastMessageAudio,
  sendMessageToBot,
  loadMoreMessages = () => {},
  storageFeedback = () => {},
  openComment = () => {},
  webStates,
  webNomId,
}) => {
  const {iconChat} = images;
  const chatStyles = createChatStyles();

  const {t, i18n} = useTranslation();
  const {PROGRAMV2} = CHAT_MODULE;
  const {divisor} = images;
  const fnb = !finishTextButton ? t('diary:home') : finishTextButton;

  const [menuOpen, setMenuOpen] = useState(false);

  const {setSession} = useContext(SessionContext);
  const {user, setUser} = useContext(UserContext);

  const {height, width} = getWidthAndHeight();

  /* bandera para mostrar siempre el menú para una empresa */
  const [featureFlagMenu, setFeatureFlagMenu] = useState(
    webStates?.WebAlwaysShowMenu !== 'false',
  );
  const isFocused = useIsFocused();

  /* bandera para mandar a la pantalla de evaluaciones */
  const [featureFlagEvaluations, setFeatureFlagEvaluations] = useState(
    webStates?.WebNavigateToEvaluations !== 'false',
  );
  const [hideOptionMenu, setHideOptionMenu] = useState(
    webStates?.WebHideOptionMenu,
  );
  const snapPoints = featureFlagMenu ? [-50, 0.2, 100, 0] : [-50, 0.5, 100, 0];

  useEffect(() => {
    if (isFocused) {
      setFeatureFlagMenu(webStates?.WebAlwaysShowMenu !== 'false');
    }
  }, [isFocused]);

  async function logout() {
    setFeatureFlagMenu(false);
    try {
      await singOut();
    } catch (e) {
      //No está logueado con Firebase
    }
    await AsyncStorage.setItem('user-language', i18n?.language);
    AsyncStorage.getItem('registerPrefix').then(async registerPrefix => {
      setSession({...initialize});
      setUser({...userInitialize});

      if (registerPrefix) {
        await clearItems([]);
        navigateTo(ROUTE_NAMES.employeeLogin, {registerPrefix}, true);
        return;
      }
      await clearItems([]);
      resetRudder();
      navigateTo(ROUTE_NAMES.login, {reset: true});
    });
  }

  /** Show menu, Se le agrega el height dependiendo */
  function showMenu(num) {
    //const heightMenu = num === '200' ? height - num : 200;
    // menuRef.current.show(heightMenu);
    setMenuOpen(true);
  }

  const renderItem = ({item}) => {
    return (
      <MessageChat
        message={item}
        onFinishConversation={onFinishConversation}
        onFinishRecommendation={onFinishRecommendation}
        onStartRecommendation={onStartRecommendation}
        recommendationHappened={recommendationHappened}
        chatStateResponse={chatStateResponse}
        showFeedback={showFeedback}
        lastMessage={lastMessage}
        lastMessageAudio={lastMessageAudio}
        storageFeedback={storageFeedback}
        openComment={openComment}
      />
    );
  };

  const DefaultView = () => <></>;

  function renderAnswers() {
    const renderFunc = {
      WAITING_FOR_RESPONSE: () => <WaitingForResponse />,
      FINISH_MESSAGE: () =>
        webStates?.WebHideFinishButton === 'true' ? null : (
          <FinishButton
            labelText={fnb}
            onFinishConversation={async () => {
              const conversationFlowWeb =
                user?.userCMS?.workCompany.variables
                  .WebDefaultConversationFlow || 'Web V2';

              await onFinishConversation();
              await onSendResponse({
                content: `/lcf ${conversationFlowWeb}`,
                isCommand: true,
              });
            }}
          />
        ),
      EXPECTING_RESPONSE: () => (
        <ResponseBox
          lastMessage={lastMessage}
          onResponse={onResponse}
          disabled={false}
          chatModule={chatModule}
          onStartAnswer={onStartAnswer}
          hasMenu={true}
          showMenu={showMenu}
          featureFlagMenu={featureFlagMenu}
        />
      ),
    }[chatStateResponse];

    if (renderFunc === undefined) {
      return <DefaultView />;
    }

    return <>{renderFunc()}</>;
  }

  function onResponse(content, type) {
    const contentResponse = type === CHAT_TYPES.TEXT ? content : '';
    const extraParams =
      type === CHAT_TYPES.TEXT
        ? null
        : {...content, audio_path: content?.file, path: content?.path};

    onSendResponse({
      content: contentResponse,
      extraParams,
      isCommand: false,
      typeMessage: type,
    });
  }

  return (
    <Box height={featureFlagMenu ? height * 0.7 : height * 0.9} px={2}>
      <FlatList
        style={chatStyles.flat}
        inverted
        //ref={chatRef}
        data={messages}
        renderItem={renderItem}
        keyExtractor={item => (item?.id ? item?.id : item?.index)}
        onEndReached={loadMoreMessages}
        onEndReachedThreshold={0.1}
      />

      <Box w={'100%'} alignSelf={'flex-end'}>
        {renderAnswers()}
      </Box>

      <SlidingPanel
        isOpen={featureFlagMenu ? featureFlagMenu : menuOpen}
        onClose={() => {
          console.log('closeeee');
          setMenuOpen(false);
        }}
        snapPoints={snapPoints}
        disableDrag={!!featureFlagMenu}
        featureFlagMenu={featureFlagMenu}>
        <Center w={'100%'}>
          <ChatMenu
            user={user}
            show={num => showMenu(num)}
            setCommand={async c => {
              console.log('c========', c);
              //hideMenu();
              setMenuOpen(false);
              if (c === 'logout') {
                await logout();
                return;
              }
              onSendResponse({content: c, isCommand: true});
            }}
            featureFlagEvaluations={featureFlagEvaluations}
            setFeatureFlagMenu={setFeatureFlagMenu}
            webNomId={webNomId}
            hideOptionMenu={hideOptionMenu}
          />
        </Center>
      </SlidingPanel>
    </Box>
  );
};

ChatSkeleton.propTypes = {
  text: PropTypes.string,
};
export default ChatSkeleton;

export const URLS = {
  termsAndConditionsT: {
    es: 'https://mindsurf.ai/terminos-y-condiciones',
    en: 'https://mindsurf.ai/terminos-y-condiciones',
  },
  privacyT: {
    es: 'https://mindsurf.ai/aviso-de-privacidad',
    en: 'https://mindsurf.ai/aviso-de-privacidad',
  },
  termsAndConditions: 'https://mindsurf.ai/terminos-y-condiciones',
  consentOfInteraction:
    'https://jenny.com.ai/consentimiento-de-interaccion-del-usuario/',
  privacy: 'https://terapia.mindsurf.ai/aviso_de_privacidad',
  mailtoSupport: 'mailto:hello@mindsurf.ai',
  apollo: 'https://mobileapi.mindsurf.ai/graphql/',
  mailContact: 'hello@mindsurf.ai',
  faq: 'https://mindsurf.ai/preguntas-frecuentes',
  socket: 'https://voice-processing-pwpxqjhnfq-uc.a.run.app',
};

import {useNavigation} from '@react-navigation/native';
import {Box, Center, HStack, Image, Pressable, Text, VStack} from 'native-base';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Dimensions, Linking} from 'react-native';
//import goToExercise from '../core/displayDetailsServices';
import {PushStateContext, UserContext} from '../hooks/SessionContext';
import images from '../res/images';
import {navigateTo} from '../navigation/MainNavigator';
import {ROUTE_NAMES} from '../navigation/MainNavigator';

/**
 * Componente que muestra la caja de ejercicio que parece en el home, herramientas, etc.
 * @param navigation
 * @param exercise objeto con la info del ejercicio desde CMS.
 * @param parentScreen
 * @param parent
 */

const BoxExcercise = ({
  exercise,
  parent = {},
  parentIcon = null,
  w,
  entryDiary = false,
}) => {
  const [type, setType] = useState();
  const {i18n} = useTranslation();
  const {iconTest} = images;
  const iconExercise = !parentIcon ? iconTest : {uri: parentIcon?.cdnUrl};

  const contentTypeMap = {
    FLOW: 'Chat',
    AUDIO: 'Audio',
    VIDEO: 'Video',
    EVALUATION_V2: {
      es: 'Evaluación',
      en: 'Evaluation',
    },
    PROGRAMA_V2_ACTIVITY: {
      es: 'Actividad',
      en: 'Activity',
    },
    ARTICLE: {
      es: 'Artículo',
      en: 'Article',
    },
    PROGRAMA_V2: {
      es: 'Terapia digital',
      en: 'Digital therapy',
    },
  };

  useEffect(() => {
    const contentType = exercise?.contentType;
    const language = i18n?.language;

    const newType =
      contentTypeMap[contentType]?.[language] || contentTypeMap[contentType];

    setType(newType);
  }, [exercise?.contentType, i18n?.language]);

  if (!exercise) {
    return null;
  }

  async function onClickExercise() {
    // Esto solo soporta evaluaciones, en el futuro agregar todos los tipos de contenido
    navigateTo(ROUTE_NAMES.evaluationIntro, {
      id: exercise.id,
      categoryID: parent?.id,
    });
  }

  return (
    <Pressable testID="box-ex-id" onPress={onClickExercise}>
      <Box h={160} w={160} px={1} mx={3} shadow={2} bg={'white'} rounded={30}>
        <VStack w={'100%'} h={'100%'}>
          <HStack
            pb={1}
            h={'100%'}
            w={'100%'}
            flex={1}
            flexDirection={'column'}
            alignItems={'center'}>
            <Box
              flex={1}
              w={'100%'}
              h={'15%'}
              pl={3}
              pr={3}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignSelf={'center'}>
              <Image
                source={iconExercise}
                size={7}
                alt="Mindsurf"
                alignSelf={'flex-start'}
                mt={5}
              />
              {exercise?.completedStatus && (
                <Text mt={3} fontSize={'xl'}>
                  {(() => {
                    switch (exercise?.completedStatus) {
                      case 'NA':
                        return null;
                      case 'NOT_STARTED':
                        return null;
                      case 'IN_PROGRESS':
                        return '📝';
                      case 'COMPLETED':
                        return '✅';
                      default:
                        return null;
                    }
                  })()}
                </Text>
              )}
            </Box>
            <Center w={'100%'} h={'70%'}>
              <Text
                alignSelf={'flex-start'}
                justifyItems={'center'}
                fontFamily={'Quicksand'}
                mx={1}
                pl={2}
                mt={2}
                lineHeight={17}
                numberOfLines={4}
                fontSize={'md'}
                fontWeight={800}
                color={'primary.100'}>
                {exercise?.title}
              </Text>
              <Text
                color={'tertiary.100'}
                fontSize={'sm'}
                fontFamily={'Quicksand'}
                fontWeight={800}
                my={entryDiary ? 4 : 3}
                mx={3}
                alignSelf={'flex-start'}>
                {`${type}`}
              </Text>
            </Center>
          </HStack>
        </VStack>
      </Box>
    </Pressable>
  );
};

BoxExcercise.propTypes = {
  exercise: PropTypes.object.isRequired,
};
export default BoxExcercise;

import React from 'react';
import {Text, Center, Box, Pressable} from 'native-base';
import PropTypes from 'prop-types';
import AnimatableBox from '../AnimatableBox/AnimatableBox';
import {createChatStyles} from '../../styles/base';
import {useTranslation} from 'react-i18next';
import {isWeb} from '../../helpers/utils/utils';

/**
 * Componente  OptionResponse normal
 */

const OptionResponse = ({
  optionsArray,
  disabled,
  submitChip,
  onStartAnswer,
}) => {
  const chatStyles = createChatStyles();
  return (
    <AnimatableBox
      testID={'responseBox-options-id'}
      animation="fadeIn"
      duration={2000}
      style={{...chatStyles.chipsContainer}}>
      <Box
        w={'100%'}
        testID={'responseBox-options-id'}
        justifyContent="flex-end"
        alignItems="flex-end"
        flexDir="column">
        {/*<Box backgroundColor={'secondary.150'} w={'100%'} h={0.5} my={2} />*/}
        <Box
          safeAreaBottom
          px={2}
          justifyContent="flex-end"
          alignItems="flex-end"
          flexDir={'row'}
          mx={isWeb() ? 2 : 5}
          flexWrap={'wrap'}>
          {optionsArray &&
            optionsArray?.map((option, index) =>
              option !== '' ? (
                <Pressable
                  testID={'responseBox-options-id-' + index}
                  key={index}
                  style={chatStyles.chip}
                  bg={disabled ? 'secondary.10' : 'secondary.600'}
                  disabled={disabled}
                  onPress={() => {
                    submitChip(option);
                    onStartAnswer();
                  }}>
                  <Text
                    fontFamily={isWeb() ? 'Quicksand' : 'quicksand'}
                    fontWeight={800}
                    color={disabled ? 'primary.80' : 'primary.100'}>
                    {option}
                  </Text>
                </Pressable>
              ) : null,
            )}
        </Box>
      </Box>
    </AnimatableBox>
  );
};

OptionResponse.propTypes = {
  text: PropTypes.string,
};
export default OptionResponse;

const url = './images/';

const images = {
  appIcon: require(`${url}new_icons/app-icon.png`),
  splashScreenLogo: require(`${url}new_icons/splash-screen-logo.png`),
  topBarLogo: require(`${url}new_icons/top-bar-logo.png`),
  whiteLogo: require(`${url}new_icons/white-logo.png`),
  iconBlack: require(`${url}mindsurfIconBlack.png`),
  menuDots: require(`${url}menu_dots.png`),
  backArrow: require(`${url}back_arrow.png`),
  logoN: require(`${url}Logotipo_Mindsurf_Negativo01.png`),
  cyanMS: require(`${url}Cyan_MS.png`),
  pinkMS: require(`${url}Pink_MS.png`),
  achieve: require(`${url}Logro_BM.png`),
  onboarding01: require(`${url}onboarding-01.png`),
  onboarding02: require(`${url}onboarding-02.png`),
  onboarding03: require(`${url}onboarding-03.png`),
  divisor: require(`${url}divisor.png`),
  wave_bg: require(`${url}wave_bg.png`),
  wave_positive: require(`${url}wave-positive.png`),
  wave_normal: require(`${url}wave-normal.png`),
  wave_negative: require(`${url}wave-negative.png`),
  wave_gris: require(`${url}wave-gris.png`),
  wave_morada: require(`${url}wave-morada.png`),
  check_white_button: require(`${url}check_white_button.png`),
  secureIcon: require(`${url}secure_icon.png`),
  logoChat: require(`${url}LogoChat.png`),
  circlePurple: require(`${url}circlePurple.png`),
  evaluacion: require(`${url}Evaluacion_BM.png`),
  sendIcon: require(`${url}send.png`),
  iconChat: require(`${url}icon-chat.png`),
  iconTest: require(`${url}icon.png`),
  emotionHappy: require(`${url}emotionHappy.png`),
  emotionSad: require(`${url}emotionSad.png`),
  emotionDisgust: require(`${url}emotionDisgust.png`),
  emotionAngry: require(`${url}emotionAngry.png`),
  emotionDontKnow: require(`${url}emotionDontKnow.png`),
  emotionSurprise: require(`${url}emotionSurprise.png`),
  emotionFear: require(`${url}emotionFear.png`),
  autoestimaIcon: require(`${url}autoestimaIcon.png`),
  diaryEntry: require(`${url}diary-entry.png`),
  diaryResult: require(`${url}diaryResult.png`),
  cancelSubs: require(`${url}cancel-example.png`),
  paper: require(`${url}paper.png`),
  back30: require(`${url}back-30.png`),
  after30: require(`${url}after-30.png`),
  closeIcon: require(`${url}close.png`),
  logoMindsurf: require(`${url}logo-mindsurf.png`),
  backgroundGradient: require(`${url}backgroundGradient.png`),
  check_primary: require(`${url}check_primary.png`),
  meditation: require(`${url}meditacion-1.jpeg`),
  insignia: require(`${url}Insignia-ejemplo.png`),
  therapist1: require(`${url}terapeuta1.png`),
  therapist2: require(`${url}terapeuta2.png`),
  therapist3: require(`${url}terapeuta3.png`),
};

export default images;
